import React, { useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Dialog, DialogContent, DialogTitle, IconButton, Button, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import CloseIcon from '@mui/icons-material/Close';

import useAuth from '../../../../hooks/useAuth';

import AuthForm from './auth.form';
import UserMenu from './user.menu';
import VerifyForm from './verify.form';
import { lightTheme } from '../../../../theme';

export default function Authentication() {
  const { userEmail, isAuthenticated } = useAuth();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  return (
    <div>
      {isAuthenticated && <UserMenu />}
      {!isAuthenticated && <Button variant="contained" onClick={handleOpen}>Sign In</Button>}
      <ThemeProvider theme={lightTheme}>
        <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
          {loading && <LinearProgress sx={{ position: 'absolute', top: 0, left: 0, right: 0, zIndex: 1000, height: 5 }} />}
          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6" color='black' fontWeight="bold">
              Sign in
            </Typography>
            <IconButton edge="end" color="black" onClick={handleClose} aria-label="close">
              {!loading && <CloseIcon />}
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {!userEmail ? <AuthForm setLoading={setLoading} isLoading={loading} /> : <VerifyForm handleClose={handleClose} userEmail={userEmail} setLoading={setLoading} isLoading={loading} />}
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </div>
  );
}
