import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { lightTheme } from '../../../theme';
import { ThemeProvider } from '@emotion/react';

const cities = ['Bangalore', 'Mumbai'];

function CitySelection(props) {
	const { onClose, selectedValue, open } = props;

	const handleClose = () => {
		onClose(selectedValue);
	};

	const handleListItemClick = (value) => {
		onClose(value);
	};

	return (
		<ThemeProvider theme={lightTheme}>
			<Dialog onClose={handleClose} open={open} style={{ color: 'black' }}>
				<DialogTitle>Select City</DialogTitle>
				<List sx={{ pt: 0, width: '500px' }} >
					{cities.map((city) => (
						<ListItem disableGutters key={city}>
							<ListItemButton onClick={() => handleListItemClick(city)}>
								<ListItemAvatar>
									<PersonIcon />
								</ListItemAvatar>
								<ListItemText primary={city} />
							</ListItemButton>
						</ListItem>
					))}
				</List>
			</Dialog>
		</ThemeProvider>
	);
}

CitySelection.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	selectedValue: PropTypes.string.isRequired,
};

export default function CitySelectionDialog() {
	const [open, setOpen] = React.useState(false);
	const [selectedValue, setSelectedValue] = React.useState(cities[0]);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = (value) => {
		setOpen(false);
		setSelectedValue(value);
	};

	return (
		<div style={{ padding: '0 10px' }}>
			<Button
				variant="text"
				onClick={handleClickOpen}
				startIcon={<LocationOnIcon />}
				sx={{ color: 'white', margin: '0 10px' }}
			>
				{selectedValue}
			</Button>
			<CitySelection
				selectedValue={selectedValue}
				open={open}
				onClose={handleClose}
			/>
		</div>
	);
}
