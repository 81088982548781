import React from 'react';
import { Box, Typography, Grid, Button, IconButton, Avatar, Tabs, Tab, Container, ListItem } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventImageComponent from './components/images';
import EventArtistsComponent from './components/artists';
import SimilarEvents from './components/similar-events';
import EventDetailsComponent from './components/event-details';
import EventMetaComponent from './components/event-meta';

export default function EventPage() {
  return (
    <Container>
      <Box sx={{ p: 2, pt: 5 }}>
        <Grid container spacing={4}>
          <EventImageComponent />

          <EventMetaComponent />
        </Grid>

        <EventDetailsComponent />

        <EventArtistsComponent />
        <SimilarEvents />
      </Box>
    </Container>

  );
}
