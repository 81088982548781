import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box, Skeleton, Button } from '@mui/material';
import EventCard from '../../../components/event-card';

const EventCardSkeleton = () => (
  <Box sx={{ borderRadius: 2, boxShadow: 3, overflow: 'hidden', mb: 2 }}>
    <Skeleton variant="rectangular" height={150} />
    <Box sx={{ p: 2 }}>
      <Skeleton variant="text" width="80%" />
      <Skeleton variant="text" width="60%" />
      <Skeleton variant="text" width="40%" />
    </Box>
  </Box>
);

export default function ClubEvents() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
  
    const timer = setTimeout(() => setLoading(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  const events = [
    {
      title: 'Bali Arts Festival',
      image: 'https://picsum.photos/200',
      location: 'Denpasar',
      price: 'Free',
      date: 'Mar 5',
      promoted: true,
    },
    {
      title: 'Bali Kite Festival',
      image: 'https://picsum.photos/200',
      location: 'Padang Galak Beach',
      price: 'Free',
      date: 'Mar 5',
      promoted: false,
    },
    {
      title: 'Ubud Food Festival',
      image: 'https://picsum.photos/200',
      location: 'Ubud',
      price: 'Rp 150.000 – Rp 300.000',
      date: 'Mar 5',
      promoted: false,
    },
    {
      title: 'Ubud Food Festival',
      image: 'https://picsum.photos/200',
      location: 'Ubud',
      price: 'Rp 150.000 – Rp 300.000',
      date: 'Mar 5',
      promoted: false,
    },
  ];

  if(loading) {
    return (
      <Box sx={{ mt: 5, px: { xs: 2, sm: 3, md: 5 }, maxWidth: '100%' }}>
        <EventCardSkeleton />
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Club Events</Typography>
        <Button variant="text" color="primary" sx={{ textTransform: 'none' }}>
          View All
        </Button>
      </Box>
      <Grid container spacing={3}>
        {events.map((event, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <EventCard
              image={event.image}
              title={event.title}
              location={event.location}
              price={event.price}
              date={event.date}
              promoted={event.promoted}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
