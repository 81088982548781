import { CircularProgress, Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useAuth from '../../../../hooks/useAuth';
import FormComponent from './form';
import { ArtistService } from '../../../../services/artist.service';
import { useNavigate } from 'react-router-dom';

export default function OnboardingArtistsPortfolio() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  useEffect(() => {
    const fetchArtist = async () => {
      const response = await ArtistService.getArtistByUserId(user.id);
      setInitialValues(response);
    };
    fetchArtist();
  }, [user]);
  return (
    <>
      <Container maxWidth="md" sx={{ mb: 5 }}>
        <Typography variant="h4" align="left">Hello {user.name}!</Typography>
        <Typography variant="subtitle1" align="left">Let's create your artist portfolio.</Typography>
        {loading && <CircularProgress />}
        {!loading && <FormComponent setInitialValues={setInitialValues} initialValues={initialValues} setLoading={setLoading} isLoading={loading} />}
      </Container>
    </>
  )
}