import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box, Button, Skeleton } from '@mui/material';
import ArtistCard from '../../../components/artist-card';

export default function FansAlsoLiked() {
  const events = [
    {
      id: 1,
      title: 'Bali Arts Festival',
      image: 'https://picsum.photos/300',
      name: 'John Doe',
    },
    {
      id: 2,
      title: 'Bali Kite Festival',
      image: 'https://picsum.photos/300',
      name: 'John Doe',
    },
    {
      id: 3,
      title: 'Bali Kite Festival',
      image: 'https://picsum.photos/300',
      name: 'John Doe',
    }, {
      id: 4,
      title: 'Bali Kite Festival',
      image: 'https://picsum.photos/300',
      name: 'John Doe',
    },
  ];

  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const timer = setTimeout(() => setLoading(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  const renderSkeleton = () => (
    <Grid item xs={12} sm={6} md={4}>
      <Box >
        <Skeleton variant="rectangular" height={200} />
        <Box sx={{ p: 2 }}>
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="60%" />
        </Box>
      </Box>
    </Grid>
  );

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, mt: 5 }}>
        <Typography variant="h6">Fans Also Liked</Typography>
        <Button variant="text" color="primary" sx={{ textTransform: 'none' }}>
          View All
        </Button>
      </Box>
      <Grid container spacing={3}>
        {loading
          ? Array.from(new Array(3)).map((_, index) => renderSkeleton()) // Show 3 skeleton cards during loading
          : events.map((event, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <ArtistCard
                id={event.id}
                name={event.name}
                title={event.title}
                image={event.image}
              />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}
