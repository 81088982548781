import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { AuthProvider } from './contexts/auth.context';

import AppRouter from './routes';
import { darkTheme } from './theme';
function XclusiveFrontend() {
  return (
    <>
      <AuthProvider>
        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <BrowserRouter>
            <AppRouter />
          </BrowserRouter>
        </ThemeProvider>
      </AuthProvider>
    </>

  );
}

export default XclusiveFrontend;
