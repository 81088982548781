import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

import { isValidToken, setSession, renewToken } from '../utils/jwt.utils';
import { UserService } from '../services/user.service';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  userEmail: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { userEmail } = action.payload;
    return {
      ...state,
      userEmail,
    };
  },
  VERIFY: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
      userEmail: null,
    };
  },
  UPDATE_PROFILE: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    userEmail: null,
  }),
  RESET_EMAIL: (state) => ({
    ...state,
    userEmail: null,
  }),
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  verify: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  resetEmail: () => Promise.resolve(),
  updateProfile: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const refresh = localStorage.getItem('refresh');
        if (isValidToken(refresh) && state.user === null) {
          await renewToken(refresh);
          const user = await UserService.getProfile();
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              isInitialized: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              isInitialized: true,
              user: null,
            },
          });
        }

      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            isInitialized: true,
            user: null,
          },
        });
      }
    };
    initialize();
  }, []);

  const login = async (email) => {
    await UserService.login(email);
    dispatch({
      type: 'LOGIN',
      payload: {
        userEmail: email,
      },
    });
  };

  const updateProfile = async (profileData) => {
    const user = await UserService.updateProfile(profileData);
    dispatch({
      type: 'UPDATE_PROFILE',
      payload: {
        user,
      },
    });
  };

  const verify = async (email, otp) => {
    const userData = await UserService.verifyOtp(email, otp);
    const { access, refresh } = userData.tokens;
    setSession(access, refresh);
    dispatch({
      type: 'VERIFY',
      payload: {
        userEmail: null,
        isAuthenticated: true,
        user: userData,
      },
    });
  };

  const clearTempUser = () => {
    dispatch({
      type: 'RESET',
      payload: {
        isAuthenticated: false,
        isInitialized: false,
        user: null,
        tempUser: null,
      },
    });
  }

  const logout = () => {
    localStorage.clear();
    clearTempUser();
    dispatch({
      type: 'LOGOUT',
    });
  };

  const resetEmail = () => {
    dispatch({
      type: 'RESET_EMAIL',
      payload: {
        userEmail: null,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        verify,
        logout,
        updateProfile,
        resetEmail,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };