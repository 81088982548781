import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

const categories = [
  { name: 'Bollywood Night', gradient: 'linear-gradient(135deg, #36D1DC, #5B86E5)' },
  { name: 'Techno Music', gradient: 'linear-gradient(135deg, #F2994A, #F2C94C)' },
  { name: 'House Music', gradient: 'linear-gradient(135deg, #56ab2f, #a8e063)' },
];

const GradientCard = styled(Box)(({ gradient }) => ({
  background: gradient,
  borderRadius: '16px',
  height: '150px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  textAlign: 'center',
  padding: '10px',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

export default function PopularCategories() {

  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  const renderSkeleton = () => (
    <Grid item xs={12} sm={4}>
      <Box>
        <Skeleton variant="rectangular" height={150} />
        <Box sx={{ p: 2 }}>
          <Skeleton variant="text" width="80%" />
        </Box>
      </Box>
    </Grid>
  );

  return (
    <Box sx={{ padding: '5px', color: 'white' }}>
      <Typography variant="h6" sx={{ mb: 3 }}>
        Popular Categories
      </Typography>
      <Grid container spacing={3}>
        {loading
          ? Array.from(new Array(3)).map((_, index) => renderSkeleton())
          : categories.map((category, index) => (
            <Grid item xs={12} sm={4} key={index}>
            <GradientCard gradient={category.gradient}>
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                {category.name}
              </Typography>
            </GradientCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
