import React, { useState, useEffect } from 'react';
import { Grid, Box, Skeleton, Typography, IconButton, Avatar } from '@mui/material';

const EventArtistsComponent = () => {
  const [artists, setArtists] = useState([1, 2, 3, 4]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
      setArtists([1, 2, 3, 4]);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box sx={{ mt: 4 }}>
      {!loading && <Typography variant="h6">Top Artists</Typography>}
      <Grid container spacing={3} sx={{ mt: 1 }}>
        {artists.map((_, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {loading ? (
                <Skeleton variant="circular" width={40} height={40} />
              ) : (
                <Avatar />
              )}
              <Box>
                {loading ? (
                  <Skeleton variant="text" width={100} />
                ) : (
                  <>
                    <Typography variant="body1">Artist name</Typography>
                    <Typography variant="body2" color="textSecondary">@nancy_d_cardenas</Typography>
                  </>
                )}
              </Box>
              {loading ? (
                <Skeleton variant="circular" width={24} height={24} />
              ) : (
                <IconButton>
                  <span role="img" aria-label="favorite">❤️</span>
                </IconButton>
              )}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default EventArtistsComponent;