import { CircularProgress, Container, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import useAuth from '../../../../hooks/useAuth';
import ProfileForm from './profile-form';

export default function OnboardingArtistsProfile() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  return (
    <>
      <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '70vh' }}>
        {loading && <CircularProgress />}
        {!loading && (
          <>
            <Typography variant="h4" align="left">Great!</Typography>
            <Typography variant="subtitle1" align="left">Now let's get you onboarded!</Typography>
            <ProfileForm user={user} setLoading={setLoading} isLoading={loading} />
          </>
        )}
      </Container>
    </>
  )
}