import React from 'react';
import { Box, Typography, Button, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

import 'swiper/css';

// Install modules
SwiperCore.use([Navigation, Pagination]);

const StyledPaper = styled(Paper)(({ theme }) => ({
	marginTop: theme.spacing(2),
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundSize: 'cover',
	backgroundPosition: 'center',
	borderRadius: 10,
	height: 400,
	width: '100%',
}));

export default function MainCarousel() {
	return (
		<Swiper
			slidesPerView={2}
			spaceBetween={10}
			loop={true}
			slidesOffsetBefore={10}
			direction='horizontal'
			effect='slide'
			centeredSlides={true}
			speed={100}
			autoplay={{
				pauseOnMouseEnter: true,
				delay: 100,
			}}
		>
			{[1, 2, 3, 4, 5, 6, 7].map((item, index) => (
				<SwiperSlide key={index}>
					<StyledPaper style={{ backgroundImage: `url(https://picsum.photos/536/354)` }}>
					</StyledPaper>
				</SwiperSlide>
			))}
		</Swiper>
	);
}
