import React, {  useState, useEffect } from 'react';
import { Grid, Typography, Box, Card, CardMedia, CardContent, Skeleton } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EventIcon from '@mui/icons-material/Event';
import { styled } from '@mui/material/styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const EventCard = styled(Card)(({ theme }) => ({
  background: 'transparent',
  borderRadius: theme.spacing(1),
  color: 'white',
  position: 'relative',
  padding: theme.spacing(2),
  margin: theme.spacing(1),
  '&:hover': {
    scale: 1.05,
    transition: 'all 0.3s ease-in-out',
  },
}));

const NumberBackground = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: '-5px',
  fontSize: '300px',
  fontWeight: 600,
  color: 'black',
  textShadow: '0 0 10px white',
  zIndex: -1,
  lineHeight: 0.8,
}));

const EventMedia = styled(CardMedia)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  height: '200px',
  marginLeft: '70px',
  backgroundSize: 'cover',
  marginBottom: theme.spacing(2),
}));

const EventTitle = styled(Typography)(({ theme }) => ({
  mb: 1,
  fontSize: '20px',
  fontWeight: 600,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color: theme.palette.text.primary,
}));

export default function TrendingEvents() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  const events = [
    {
      title: 'asdas das da',
      image: 'https://picsum.photos/200/300',
      location: 'Bhartiya Mall of Bangalore',
      date: 'Tue, Jan 28, 2025',
    },
    {
      title: 'Bollywood Night',
      image: 'https://picsum.photos/200/300',
      location: 'Denpasar',
      date: 'Tue, Jan 28, 2025',
    },
    {
      title: 'Bollywood Night',
      image: 'https://picsum.photos/200/300',
      location: 'Denpasar',
      date: 'Tue, Jan 28, 2025',
    },
    {
      title: 'Bollywood Night',
      image: 'https://picsum.photos/200/300',
      location: 'Denpasar',
      date: 'Tue, Jan 28, 2025',
    },
    {
      title: 'Bollywood Night',
      image: 'https://picsum.photos/200/300',
      location: 'Denpasar',
      date: 'Tue, Jan 28, 2025',
    },
    {
      title: 'Bollywood Night',
      image: 'https://picsum.photos/200/300',
      location: 'Denpasar',
      date: 'Tue, Jan 28, 2025',
    },{
      title: 'Bollywood Night',
      image: 'https://picsum.photos/200/300',
      location: 'Denpasar',
      date: 'Tue, Jan 28, 2025',
    },
    {
      title: 'Bollywood Night',
      image: 'https://picsum.photos/200/300',
      location: 'Denpasar',
      date: 'Tue, Jan 28, 2025',
    },
    {
      title: 'Bollywood Night',
      image: 'https://picsum.photos/200/300',
      location: 'Denpasar',
      date: 'Tue, Jan 28, 2025',
    },
    {
      title: 'Bollywood Night',
      image: 'https://picsum.photos/200/300',
      location: 'Denpasar',
      date: 'Tue, Jan 28, 2025',
    },
  ];

  if(loading) {
    return (
      <Box sx={{ 
        mt: 5, 
        px: { xs: 2, sm: 3, md: 5 },
        maxWidth: '100%' 
      }}>
        <Skeleton variant="rectangular" height={300} />
      </Box>
    )
  }
  return (
    <Box sx={{ 
      mt: 5, 
      px: { xs: 2, sm: 3, md: 5 },
      maxWidth: '100%' 
    }}>
      <Typography variant="h6">Trending events in Bangalore</Typography>
      <Swiper
         slidesPerView={1} // Default slide number for smallest screen
         spaceBetween={10} // Default spacing for smallest screen
         breakpoints={{
           640: {
             slidesPerView: 1.5,
             spaceBetween: 20,
           },
           1024: {
             slidesPerView: 3,
             spaceBetween: 30,
           },
         }}
      >
        {events.map((event, index) => (
          <SwiperSlide key={index}>
            <EventCard>
              <NumberBackground>{index + 1}</NumberBackground>
              <EventMedia
                image={event.image}
                title={event.title}
              />
              <CardContent>
                <EventTitle compo>
                  {event.title}
                </EventTitle>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <LocationOnIcon sx={{ fontSize: 'small', mr: 1 }} />
                  <Typography variant="body2">{event.location}</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <EventIcon sx={{ fontSize: 'small', mr: 1 }} />
                  <Typography variant="body2">{event.date}</Typography>
                </Box>
              </CardContent>
            </EventCard>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
}
