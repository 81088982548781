import React, { useState } from 'react';
import { Typography, Button, Box } from '@mui/material';

function ExploreEvents() {
	const [dateRange, setDateRange] = useState('Today');

	return (
		<Box sx={{ mt: 2 }}>

			<Typography variant="h6" sx={{ mb: 2 }}>Explore Events</Typography>
			{['Today', 'This Weekend', 'This Month', 'Custom Dates'].map((range) => (
				<Button
					key={range}
					onClick={() => setDateRange(range)}
					variant={dateRange === range ? 'contained' : 'outlined'}
					color="primary"
					sx={{ mx: 0.5, color: dateRange === range ? 'black' : 'white', borderColor: 'white' }}
				>
					{range}
				</Button>
			))}
		</Box>
	);
}
export default ExploreEvents;
