import React from 'react';
import { Box, Typography, Grid, Button, Avatar, ListItem } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const EventMetaComponent = () => {
  return (
    <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Typography variant="h6" fontWeight="bold">Light Dance Show</Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1, ml: 2 }}>
          <CalendarTodayIcon fontSize="small" />
          <Typography variant="body1">16th September</Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1, ml: 2 }}>
          <AccessTimeIcon fontSize="small" />
          <Typography variant="body1">9:00 PM - 01:00 AM</Typography>
        </Box>

        <ListItem
          sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}
          secondaryAction={
            <Button variant="text" color="secondary" sx={{ ml: 1 }}>Get direction</Button>
          }
        >
          <LocationOnIcon fontSize="small" />
          <Typography variant="body1">Kormangala, Bangalore</Typography>

        </ListItem>

        <ListItem
          sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}
          secondaryAction={
            <Button variant="text" color="secondary">Follow</Button>
          }
        >
          <Avatar />
          <Box>
            <Typography variant="body1">Organiser name</Typography>
          </Box>
        </ListItem>

        <Typography variant="body2" sx={{ mt: 2 }}>Choose a time slot</Typography>
        <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
          <Button variant="outlined">7:00 PM - 9:00 PM</Button>
          <Button variant="outlined">9:00 PM - 01:00 AM</Button>
        </Box>

        <Typography variant="h5" sx={{ mt: 2 }}>₹1,000 onwards</Typography>
        <Button variant="contained" sx={{ mt: 2 }}>Book Now</Button>
      </Grid>
  );
};

export default EventMetaComponent;