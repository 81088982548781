import Container from "@mui/material/Container";
import MainCarousel from "./components/carousel";
import ExploreEvents from "./components/explore-events";
import TrendingEvents from "./components/trending";
import ClubEvents from "./components/club-events";
import FeaturedArtists from "./components/featured-artists";
import ExclusiveCard from "./components/exlusive-card";
import ConcertEvents from "./components/conerts";
import PopularEvents from "./components/popular-events";
import PopularCategories from "./components/categories";
import SubscriptionForm from "./components/subscription-form";
function Home() {
  return (
    <div>
      <MainCarousel />
      <Container>
        <ExploreEvents />
        <TrendingEvents />
        <ClubEvents />
        <ExclusiveCard />
        <FeaturedArtists />
        <ConcertEvents />
        <PopularCategories />
        <PopularEvents />
        <SubscriptionForm />
      </Container>
    </div>
  );
}

export default Home;
