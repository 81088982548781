import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import XclusiveLogo from '../../../components/logo';

const StyledCard = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '150px',
  border: '1px solid rgba(255, 255, 255, 0.3)',
  borderRadius: '16px',
  
}));

export default function ExclusiveCard() {
  return (
    <StyledCard>
      <Typography variant="h5" color="white">
        <XclusiveLogo />
      </Typography>
    </StyledCard>
  );
}
