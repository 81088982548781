import React, { useState, useEffect } from 'react';
import { Box, Typography, Skeleton } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default function ArtGallery() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const renderSkeleton = () => (
    <Skeleton
      variant="rectangular"
      animation="wave"
      sx={{
        backgroundColor: '#999',
        borderRadius: 4,
        height: 200,
        width: '100%',
      }}
    />
  );

  const galleryImages = [1, 2, 3, 4, 5];

  return (
    <Box sx={{ mt: 5 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h5" fontWeight="bold">
          Art in Motion: Gallery
        </Typography>
      </Box>

      <Swiper
        spaceBetween={20} 
        slidesPerView={2}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
      >
        {galleryImages.map((_, index) => (
          <SwiperSlide key={index}>
            {isLoading ? (
              renderSkeleton()
            ) : (
              <Box
                sx={{
                  backgroundColor: '#999',
                  borderRadius: 4,
                  height: 200,
                  width: '100%',
                
                }}
              />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
}
