import React from 'react';
import { AppBar, Toolbar, Box, Container } from '@mui/material';
import XclusiveLogo from '../../../components/logo';
import SearchInput from './search';
import UserProfile from './authentication';
import CitySelectionDialog from './city-select';

const Header = () => {
  return (
    <header>
      <AppBar position="fixed" sx={{ background: 'transparent' }}>
        <Container>
          <Toolbar>
            <XclusiveLogo />
            <Box sx={{ flexGrow: 0.2 }} />
            <SearchInput />
            <Box sx={{ flexGrow: 1 }} />
            <CitySelectionDialog />
            <UserProfile />
          </Toolbar>
        </Container>
      </AppBar>
    </header>
  );
};

export default Header;