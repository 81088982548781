import React from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from '@mui/material';
import Footer from './components/footer';
import Header from './components/header';

const Layout = () => {
  return (
    <div style={{  }}>
      <Header />
      <main style={{ marginTop: '64px' }}>
        <Outlet />
      </main>
      <footer>
        <Container>
          <Footer />
        </Container>
      </footer>
    </div>
  );
};

export default Layout;