import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, Card, CardMedia, CardContent} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const EventCardComponent = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  color: 'white',
  position: 'relative',
  background: 'transparent',
  '&:hover': {
    transform: 'scale(1.05)',
    transition: 'transform 0.3s',
  },
}));

const PromotedBadge = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  left: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  padding: theme.spacing(0.2, 0.5),
  borderRadius: theme.shape.borderRadius,
  fontSize: '12px',
  fontWeight: 'bold',
}));

const EventDateBadge = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  backgroundColor: '#FFFFFF',
  color: theme.palette.primary.main,
  fontSize: '12px',
  padding: theme.spacing(0.2, 0.5),
  borderRadius: theme.shape.borderRadius,
  textAlign: 'center',
  fontWeight: 'bold',
}));

const EventMedia = styled(CardMedia)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  height: '150px',
  backgroundSize: 'cover',
  marginBottom: theme.spacing(2),
}));



export default function EventCard({ image, title, location, price, date, promoted }) {
  const navigate = useNavigate();

  const redirectToEventPage = () => {
    navigate('/event/124');
  };

  return (
    <>
      <EventCardComponent onClick={redirectToEventPage}>
        <EventMedia
          image={image}
          title={title}
        />
        {promoted && <PromotedBadge>Promoted</PromotedBadge>}
        <EventDateBadge>
          {date.split(' ')[0]}<br />{date.split(' ')[1]}
        </EventDateBadge>
        <CardContent sx={{ padding: '0px', backgroundColor: 'transparent' }}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            {title}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <LocationOnIcon sx={{ fontSize: 'small', mr: 1 }} />
            <Typography variant="body2">{location}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <LocalOfferIcon sx={{ fontSize: 'small', mr: 1 }} />
            <Typography variant="body2">{price}</Typography>
          </Box>
        </CardContent>
      </EventCardComponent>
    </>
  );
}

EventCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  promoted: PropTypes.bool.isRequired,
};