import React from 'react';
import { Box, Grid, Typography, Avatar } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';

export default function VenueGrid() {
  const venues = [
    { name: 'Venue Name', location: 'Bangalore, India' },
    { name: 'Venue Name', location: 'Bangalore, India' },
    { name: 'Venue Name', location: 'Bangalore, India' },
    { name: 'Venue Name', location: 'Bangalore, India' },
    { name: 'Venue Name', location: 'Bangalore, India' },
    { name: 'Venue Name', location: 'Bangalore, India' },
    { name: 'Venue Name', location: 'Bangalore, India' },
    { name: 'Venue Name', location: 'Bangalore, India' },
  ];

  return (
    <Box sx={{  color: '#fff', mt: 5 }}>
      {/* Section Title */}
      <Typography variant="h5" fontWeight="bold" sx={{ mb: 4 }}>
        Stages Played: Venues & Cities
      </Typography>

      {/* Venue Grid */}
      <Grid container spacing={3}>
        {venues.map((venue, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
              {/* Avatar Placeholder */}
              <Avatar sx={{ backgroundColor: '#aaa' }} />
              
              {/* Venue Details */}
              <Box>
                <Typography variant="body1" fontWeight="bold">
                  {venue.name}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <LocationOnIcon fontSize="small" />
                  <Typography variant="body2" color="textSecondary">
                    {venue.location}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
