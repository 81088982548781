import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Button, IconButton, Skeleton, useTheme, useMediaQuery } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { styled } from '@mui/material/styles';

const BookNowButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  borderRadius: '8px',
  padding: '10px 20px',
  textTransform: 'none',
  border: '1px solid rgba(255, 255, 255, 0.3)',

}));

export default function UpcomingShows() {
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box sx={{ paddingTop: '40px' }}>
      <Typography variant="h5" fontWeight="bold" sx={{ mb: 4 }}>
        My upcoming shows
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <IconButton sx={{ color: 'white' }}>
          <i className="fas fa-filter" />
        </IconButton>
        <Typography sx={{ ml: 1, mr: 2 }}>Sort by</Typography>
        <Button variant="outlined" sx={{ color: 'white', borderRadius: '8px', borderColor: 'rgba(255, 255, 255, 0.3)' }}>
          All Location
        </Button>
        <Button variant="outlined" sx={{ ml: 2, color: 'white', borderRadius: '8px', borderColor: 'rgba(255, 255, 255, 0.3)' }}>
          Custom Dates
        </Button>
        <Button variant="outlined" sx={{ ml: 2, color: 'white', borderRadius: '8px', borderColor: 'rgba(255, 255, 255, 0.3)' }}>
          Today
        </Button>
        <Button variant="outlined" sx={{ ml: 2, color: 'white', borderRadius: '8px', borderColor: 'rgba(255, 255, 255, 0.3)' }}>
          This Weekend
        </Button>
        <Button variant="outlined" sx={{ ml: 2, color: 'white', borderRadius: '8px', borderColor: 'rgba(255, 255, 255, 0.3)' }}>
          This Month
        </Button>
      </Box>

      {[...Array(5)].map((_, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px',
            borderRadius: '12px',
            mb: 2,
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              {loading ? (
                <Skeleton variant="rectangular" width={65} height={60} />
              ) : (
                <Box
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    textAlign: 'center',
                    padding: '10px 10px',
                    width: '65px',
                  }}
                >
                  <Typography variant="h6" fontWeight="bold" color="black">
                    Aug 12
                  </Typography>
                </Box>
              )}
            </Grid>
            <Grid item>
              {loading ? (
                <>
                  <Skeleton width="100px" />
                  <Skeleton width="60px" sx={{ mt: 1 }} />
                </>
              ) : (
                <>
                  <Typography variant="h6" fontWeight="bold" sx={{ color: 'white' }}>
                    Sajanka
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'gray' }}>
                    Bangalore
                  </Typography>
                </>
              )}
            </Grid>

            <Grid item>
              {loading ? (
                <Skeleton width="50px" />
              ) : (
                <Typography variant="body2" sx={{ color: 'white' }}>
                  11:00pm
                </Typography>
              )}
            </Grid>
          </Grid>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {loading ? (
              <>
                <Skeleton variant="circular" width={40} height={40} sx={{ mr: 2 }} />
                <Skeleton width="80px" height="40px" />
              </>
            ) : (
              <>
                <IconButton sx={{ color: 'white' }}>
                  <FavoriteBorderIcon />
                </IconButton>
                <Typography variant="body2" sx={{ color: 'white', marginRight: '20px' }}>
                  3.1K
                </Typography>
                <Button variant="outlined" sx={{ color: 'white', borderRadius: '8px', width: '150px' }}>
                  Book Now
                </Button>
              </>
            )}
          </Box>
        </Box>
      ))}

      <Box sx={{ textAlign: 'center', mt: 2 }}>
        {loading ? (
          <Skeleton variant="rectangular" width={140} height={40} />
        ) : (
          <BookNowButton>Show more</BookNowButton>
        )}
      </Box>
    </Box>
  );
}
