import axios from '../utils/axios.utils';

const _wrapper = (path) => `/auth${path}`

const _endpoints = {
  login: _wrapper('/send-otp'),
  register: _wrapper('/register'),
  verifyOtp: _wrapper('/verify'),
  renewToken: _wrapper('/refresh-token'),
  profile: _wrapper('/profile'),
  updateProfile: '/user/profile',
}

export const UserService = {
  login: (email) => axios.post(_endpoints.login, { email }),
  register: (email) => axios.post(_endpoints.register, { email }),
  renewToken: (token) => axios.post(_endpoints.renewToken, { token }),
  verifyOtp: (email, otp) => axios.post(_endpoints.verifyOtp, { email, otp }),
  getProfile: () => axios.get(_endpoints.profile),
  updateProfile: (profileData) => axios.patch(_endpoints.updateProfile, profileData),
};