import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Divider } from '@mui/material';
import { Google, Apple } from '@mui/icons-material';
import { UserService } from '../../../../services/user.service';
import useAuth from '../../../../hooks/useAuth';

const AuthForm = ({ setLoading, isLoading, hideSocials = false, title, subtitle }) => {

  const { login } = useAuth();

  const [email, setEmail] = useState('');

  const [error, setError] = useState('');

  const handleChange = (e) => {
    setEmail(e.target.value);
  }

  const handleSubmit = async (e) => {
    setLoading(true);
    try {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!email || !emailRegex.test(email)) {
        setError('Please enter a valid email address');
        return;
      }
      setError('');
      await login(email);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {title && <Typography variant="h4" gutterBottom>{title}</Typography>}
      {subtitle && <Typography variant="body1" gutterBottom>{subtitle}</Typography>}
      <Box sx={{ mt: 4, width: '100%' }}>
        <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>

          <TextField
            fullWidth
            placeholder="Email address"
            variant="outlined"
            type="email"
            disabled={isLoading}
            onChange={handleChange}
            error={!email}
            helperText={!email ? 'Email is required' : ''}
            sx={{
              mb: 3,
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px',
              },
            }}
          />

          <Button
            fullWidth
            disabled={isLoading}
            variant="contained"
            color="primary"
            type="submit"
            sx={{
              borderRadius: '10px',
              padding: '12px 0',
              mb: 3,
            }}
          >
            Next
          </Button>
        </form>
      </Box>

      {!hideSocials && <>
        <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
          <Divider sx={{ flexGrow: 1 }} />
          <Typography variant="body2" sx={{ mx: 2, color: 'gray' }}>
            or
          </Typography>
          <Divider sx={{ flexGrow: 1 }} />
        </Box>
        <Button
          fullWidth
          disabled={isLoading}
          variant="outlined"
          startIcon={<Google />}
          sx={{
            mb: 2,
            borderColor: 'rgba(0, 0, 0, 0.1)',
            color: 'black',
            borderRadius: '10px',
            textTransform: 'none',
          }}
        >
          Continue With Google
        </Button>

        <Button
          fullWidth
          disabled={isLoading}
          variant="contained"
          startIcon={<Apple />}
          sx={{
            backgroundColor: 'black',
            color: 'white',
            borderRadius: '10px',
            textTransform: 'none',
            padding: '10px 0',
            '&:hover': {
              backgroundColor: '#333',
            },
          }}
        >
          Continue With Apple
        </Button>
      </>}
    </>
  );
};

export default AuthForm;
