
import React from 'react';
import Container from "@mui/material/Container";
import ProfileSection from './components/profile-section';
import UpcomingShows from './components/upcoming-shows';
import FansAlsoLiked from './components/fans-also-liked';
import VenueGrid from './components/venue-grid';
import ArtGallery from './components/art-gallery';
import StatsSection from './components/stats-section';
function Artists() {
  return (
    <div style={{ marginBottom: "100px" }}>
      <Container>
        <ProfileSection />
        <UpcomingShows />
        <StatsSection />
        <ArtGallery />
        <VenueGrid />
        <FansAlsoLiked />
      </Container>
    </div>
  );
}

export default Artists;
