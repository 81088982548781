import React from 'react';
import { Box, Grid, Typography, IconButton, Divider, Link } from '@mui/material';
import { Facebook, Instagram, LinkedIn, YouTube } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import XclusiveLogo from '../../../components/logo';

// Custom styled IconButton to achieve circular border
const SocialIconButton = styled(IconButton)(({ theme }) => ({
  borderRadius: '50%',
  border: '1px solid white',
  color: 'white',
  padding: theme.spacing(1),
  marginLeft: theme.spacing(1),
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

export default function Footer() {
  return (
    <Box sx={{ padding: '20px' }}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
          <XclusiveLogo />
        </Grid>

        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <SocialIconButton>
            <Facebook />
          </SocialIconButton>
          <SocialIconButton>
            <Instagram />
          </SocialIconButton>
          <SocialIconButton>
            <LinkedIn />
          </SocialIconButton>
          <SocialIconButton>
            <YouTube />
          </SocialIconButton>
        </Grid>
      </Grid>

      <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', my: 2 }} />

      <Grid container justifyContent="center" spacing={3}>
        {['Privacy Policies', 'Refund Policies', 'Terms & Conditions', 'Cookies', 'About', 'Contact', 'Career'].map((link, index) => (
          <Grid item key={index}>
            <Link href="#" underline="hover" color="white" sx={{ fontSize: '14px' }}>
              {link}
            </Link>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ mt: 2, textAlign: 'center' }}>
        <Typography variant="body2" color="white">
          © 2024 Xclusive • All Rights Reserved
        </Typography>
      </Box>
    </Box>
  );
}
