import React from 'react';
import { AppBar, Toolbar, Container, Box, Button } from '@mui/material';
import XclusiveLogo from '../../../components/logo';
import useAuth from '../../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const { logout, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  return (
    <header>
      <AppBar position="fixed" sx={{ background: 'transparent' }}>
        <Container>
          <Toolbar>
            <XclusiveLogo />
            <Box sx={{ flexGrow: 2 }} />
            {isAuthenticated && <Button variant="contained" color="primary" onClick={() => {
              logout();
              navigate('/onboarding/artist/profile');
            }}>Logout</Button>}

          </Toolbar>
        </Container>
      </AppBar>
    </header>
  );
};

export default Header;