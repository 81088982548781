import React from 'react';
import { Box, Grid, Typography, Divider } from '@mui/material';

export default function StatsSection() {
  const stats = [
    { value: 546, label: 'Shows performed' },
    { value: 546, label: 'Cities' },
    { value: 546, label: 'Music published' },
    { value: 42, label: 'Awards' },
  ];

  return (
    <Box sx={{ p: 4 }}>
      <Grid container alignItems="center" justifyContent="center">
        {stats.map((stat, index) => (
          <Grid item xs={12} sm={3} key={index} sx={{ textAlign: 'center', position: 'relative' }}>
            {/* Number */}
            <Typography variant="h3" fontWeight="bold">
              {stat.value}
            </Typography>
            {/* Label */}
            <Typography variant="body1" color="textSecondary">
              {stat.label}
            </Typography>

            {/* Divider (except after the last item) */}
            {index < stats.length - 1 && (
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  height: '60%',
                  borderColor: '#fff',
                }}
              />
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
