import React, { useState } from 'react';
import { Grid, Box, Skeleton } from '@mui/material';

const EventImageComponent = () => {
  const [mainImage, setMainImage] = useState("https://picsum.photos/600/400");
  
  const handleThumbnailClick = (src) => {
    setMainImage(src);
  };

  const thumbnails = [
    "https://picsum.photos/600/400",
    "https://picsum.photos/601/400",
    "https://picsum.photos/602/400",
    "https://picsum.photos/603/400",
    "https://picsum.photos/604/400",
    "https://picsum.photos/600/400",
    "https://picsum.photos/601/400",
    "https://picsum.photos/602/400",
    "https://picsum.photos/603/400",
    "https://picsum.photos/604/400"
  ];

  const thumbnailWidth = `calc(${100 / thumbnails.length}% - 8px)`;

  return (
    <>
      <Grid item xs={12} md={6}>
        <Box sx={{ position: 'relative', width: '100%', borderRadius: '8px', overflow: 'hidden' }}>
          <img
            src={mainImage}
            alt="Light Dance Show"
            style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
          />
          {!mainImage && <Skeleton variant="rectangular" width="100%" height={400} />}
        </Box>
        <Box sx={{ display: 'flex', gap: 1, mt: 2, flexWrap: 'wrap' }}>
          {thumbnails.map((src, index) => (
            <img
              key={index}
              src={src}
              alt={`Thumbnail ${index + 1}`}
              style={{ width: thumbnailWidth, borderRadius: '4px', cursor: 'pointer', objectFit: 'cover', height: '60px' }}
              onClick={() => handleThumbnailClick(src)}
            />
          ))}
        </Box>
      </Grid>
    </>
  );
};

export default EventImageComponent;