import React from 'react';
import { useRoutes } from 'react-router-dom';
import AuthGuard from './auth.guard';

import Home from '../pages/home';
import Layout from '../layout/main';
import Artists from '../pages/artist';
import EventPage from '../pages/event';
import OnboardingLayout from '../layout/onboarding';
import OnboardingArtists from '../pages/onboarding/artists';
import OnboardingArtistsProfile from '../pages/onboarding/artists/profile';
import OnboardingArtistsPortfolio from '../pages/onboarding/artists/porfolio';
import ThankYouPage from '../pages/onboarding/artists/porfolio/thank-you';


const AppRouter = () => {
  return useRoutes([
    {
      path: '/',
      element: <Layout />,
      children: [
        { index: true, element: <Home /> },
        { path: '/event/:id', element: <EventPage /> },
        { path: '/artists', element: <Artists /> },
        { path: '/artist/:id', element: <Artists /> },
      ],
    },
    {
      path: '/onboarding',
      element: <OnboardingLayout />,
      children: [
        { path: '/onboarding/artists', element: <OnboardingArtists /> },
        { path: '/onboarding/artist/profile', element: (<AuthGuard><OnboardingArtistsProfile /></AuthGuard>) },
        { path: '/onboarding/artist/portfolio', element: (<AuthGuard><OnboardingArtistsPortfolio /></AuthGuard>) },
        { path: '/onboarding/artist/portfolio/thank-you', element: <ThankYouPage /> },
      ],
    }
  ]);
};

export default AppRouter;
