import React from 'react';
import PropTypes from 'prop-types';
import { Box, CardMedia, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const CustomCard = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: 200,
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  '&:hover': {
    transform: 'scale(1.05)',
    transition: 'transform 0.3s',
  },
}));

const OverlayText = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(2),
  left: theme.spacing(2),
  color: 'white',
  zIndex: 2,
}));

const CustomCardMedia = styled(CardMedia)(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  filter: 'brightness(0.6)',
}));

const ArtistCard = ({ id, name, title, image }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/artist/${id}`);
  };

  return (
    <CustomCard onClick={handleClick}>
      <CustomCardMedia
        component="img"
        image={image} 
        alt={name}
      />
      <OverlayText>
        <Typography variant="caption" sx={{ fontWeight: 300 }}>
          {title}
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          {name}
        </Typography>
      </OverlayText>
    </CustomCard>
  );
};

ArtistCard.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};


export default ArtistCard;
