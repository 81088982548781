import React from 'react';
import { Container, Typography } from '@mui/material';
export default function ThankYouPage() {
  return (
    <div>
      <Container maxWidth="md" sx={{ mb: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '50vh' }} >
        <Typography variant="h4">Thank You!</Typography>
        <Typography variant="subtitle1">Your portfolio has been created successfully.</Typography>
      </Container>
    </div>
  );
};