import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Avatar, Button, IconButton, Skeleton } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import LaunchIcon from '@mui/icons-material/Launch';
import { styled } from '@mui/material/styles';

const EnquireButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  borderRadius: '8px',
  padding: '10px 20px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#e03972',
  },
}));

const ProfileSection = () => {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
  
    const timer = setTimeout(() => setLoading(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box
      sx={{
        paddingTop: '40px',
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} md={2}>
          {loading ? (
            <Skeleton variant="circular" width={120} height={120} />
          ) : (
            <Avatar
              alt="Sajanka"
              src="https://picsum.photos/id/237/200/300"
              sx={{ width: 120, height: 120 }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={8}>
          {loading ? (
            <>
              <Skeleton width="60%" height={40} />
              <Skeleton width="80%" height={20} sx={{ mt: 2 }} />
              <Skeleton width="50%" height={20} sx={{ mt: 1 }} />
              <Skeleton variant="rectangular" width={120} height={40} sx={{ mt: 2 }} />
            </>
          ) : (
            <>
              <Typography variant="h4" fontWeight="bold" sx={{ color: 'white' }}>
                Sajanka
              </Typography>
              <Typography variant="body1" sx={{ color: 'rgba(255, 255, 255, 0.8)', marginTop: '16px' }}>
                Avishai Grinbaum, 24 years old is the man behind this amazing project called 'Sajanka'.
                His professional musical career began in 2014. His style is very unique, melodic ethnic trance
                that opens the soul and mind...{' '}
                <Typography component="span" sx={{ color: '#FF4081', fontWeight: 'bold' }}>
                  see more
                </Typography>
              </Typography>
              <EnquireButton sx={{ marginTop: '20px' }}>Enquire Now</EnquireButton>
            </>
          )}
        </Grid>
      </Grid>

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        {loading ? (
          <>
            <Skeleton variant="circular" width={40} height={40} sx={{ mb: 2 }} />
            <Skeleton variant="circular" width={40} height={40} />
          </>
        ) : (
          <>
            <IconButton sx={{ color: 'white' }}>
              <FavoriteBorderIcon />
            </IconButton>
            <IconButton sx={{ color: 'white' }}>
              <LaunchIcon />
            </IconButton>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ProfileSection;
