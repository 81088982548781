import React from 'react';
import { Box, Typography, TextField, Button, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';


const FormContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5),
  textAlign: 'center',
  borderRadius: theme.spacing(2),
  width: '100%',
  margin: 'auto',
}));


const CustomTextField = styled(TextField)(({ theme }) => ({
  
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.spacing(1),
    color: 'white',
    backgroundColor: '#181818',
    border: '1px solid rgba(255, 255, 255, 0.3)', 
  },
  '& input': {
    padding: '10px',
    width: '300px',
    color: 'white',
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  backgroundColor: theme.palette.primary.main,
  padding: '10px 20px',
  borderRadius: theme.spacing(1),
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function SubscriptionForm() {
  return (
    <FormContainer style={{marginTop: 50, marginBottom: 50 }}>
      <Typography variant="h4" fontWeight="bold" color="white" gutterBottom>
        You Do Not Want to Miss Out on this!
      </Typography>
      <Typography variant="body1" color="gray" gutterBottom>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
      </Typography>
      <br />
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid item>
          <CustomTextField 
            variant="outlined" 
            placeholder="Your Email" 
            InputProps={{ style: { color: 'white' } }} 
          />
        </Grid>
        <Grid item>
          <SubmitButton variant="contained">
            Submit
          </SubmitButton>
        </Grid>
      </Grid>
    </FormContainer>
  );
}
