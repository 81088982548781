import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box, Button, Skeleton } from '@mui/material';
import EventCard from '../../../components/event-card';

const events = [
  {
    title: 'Bali Arts Festival',
    image: 'https://picsum.photos/200',
    location: 'Denpasar',
    price: 'Free',
    date: 'Mar 5',
    promoted: true,
  },
  {
    title: 'Bali Kite Festival',
    image: 'https://picsum.photos/200',
    location: 'Padang Galak Beach',
    price: 'Free',
    date: 'Mar 5',
    promoted: false,
  },
  {
    title: 'Ubud Food Festival',
    image: 'https://picsum.photos/200',
    location: 'Ubud',
    price: 'Rp 150.000 – Rp 300.000',
    date: 'Mar 5',
    promoted: false,
  },
  {
    title: 'Ubud Food Festival',
    image: 'https://picsum.photos/200',
    location: 'Ubud',
    price: 'Rp 150.000 – Rp 300.000',
    date: 'Mar 5',
    promoted: false,
  },
  {
    title: 'Bali Kite Festival',
    image: 'https://picsum.photos/200',
    location: 'Padang Galak Beach',
    price: 'Free',
    date: 'Mar 5',
    promoted: false,
  },
  {
    title: 'Ubud Food Festival',
    image: 'https://picsum.photos/200',
    location: 'Ubud',
    price: 'Rp 150.000 – Rp 300.000',
    date: 'Mar 5',
    promoted: false,
  },
  {
    title: 'Ubud Food Festival',
    image: 'https://picsum.photos/200',
    location: 'Ubud',
    price: 'Rp 150.000 – Rp 300.000',
    date: 'Mar 5',
    promoted: false,
  },
];

export default function SimilarEvents() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const timer = setTimeout(() => setLoading(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  const renderSkeleton = () => (
    <Grid item xs={12} sm={6} md={3}>
      <Box>
        <Skeleton variant="rectangular" height={150} />
        <Box sx={{ p: 2 }}>
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="60%" />
          <Skeleton variant="text" width="40%" />
        </Box>
      </Box>
    </Grid>
  );

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3, mt: 5 }}>
        <Typography variant="h6">Similar Events</Typography>
      </Box>
      <Grid container spacing={2}>
        {loading
          ? Array.from(new Array(4)).map((_, index) => renderSkeleton()) // Render 4 skeleton cards during loading
          : events.map((event, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <EventCard
                image={event.image}
                title={event.title}
                location={event.location}
                price={event.price}
                date={event.date}
                promoted={event.promoted}
              />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}
