import React, { useState } from "react";
import { Grid, TextField, MenuItem, Select, Chip, InputLabel, FormControl, Box } from "@mui/material";

// Art form and category data
const artForms = {
  Music: ["Classical", "Jazz", "Rock", "Electronic"],
  Photography: ["Portrait", "Landscape", "Abstract", "Wildlife"],
  Literature: ["Poetry", "Novel", "Short Story", "Non-fiction"],
  Theater: ["Play", "Musical", "Opera", "Ballet"],
  Film: ["Movie", "Documentary", "Animation", "Short Film"],
  Dance: ["Ballet", "Hip-Hop", "Modern", "Jazz"],
  Fashion: ["Design", "Fashion Show", "Fashion Photography", "Fashion Styling"],
  Visual: ["Illustration", "Graphic Design", "Animation", "Digital Art"],
  Other: ["Other"],
};

const ArtFormCategoryComponent = ({ formData, setFormData }) => {
  const [selectedArtForm, setSelectedArtForm] = useState(formData.primaryArtForm || "");

  const handleArtFormChange = (e) => {
    const artForm = e.target.value;
    setSelectedArtForm(artForm);

    // Reset category when art form changes
    setFormData({
      ...formData,
      primaryArtForm: artForm,
      category: "",
    });
  };

  const handleCategoryChange = (event) => {
    const selectedCategories = event.target.value;
    // Convert array to comma-separated string and update form data
    setFormData({
      ...formData,
      category: selectedCategories.join(","),
    });
  };

  return (
    <Grid container spacing={2}>
      {/* Primary Art Form Dropdown */}
      <Grid item xs={12} sm={6}>
        <TextField
          label="Primary Art Form"
          name="primaryArtForm"
          value={formData.primaryArtForm || ""}
          onChange={handleArtFormChange}
          select
          fullWidth
          variant="outlined"
          sx={{
            height: '56px', // Ensure consistent height
          }}
        >
          {Object.keys(artForms).map((artForm) => (
            <MenuItem key={artForm} value={artForm}>
              {artForm}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      {/* Multi-Select Category Dropdown */}
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth variant="outlined" sx={{ height: '56px' }}>
          <InputLabel>Category</InputLabel>
          <Select
            label="Category"
            name="category"
            multiple
            value={formData.category ? formData.category.split(",") : []} // Convert comma-separated string to array
            onChange={handleCategoryChange}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            sx={{
              minHeight: '56px', // Ensure minimum height matches the TextField
              display: 'flex',
              alignItems: 'center', // Vertically align items in the center
            }}
          >
            {selectedArtForm &&
              artForms[selectedArtForm].map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ArtFormCategoryComponent;
